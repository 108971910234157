import React, { useState, useEffect } from 'react';
import './App.css';
import { API } from './config';

function App() {
  const [tagImport, setTagImport] = useState('');
  const [tags, setTags] = useState('');
  const [titlePrompt, setTitlePrompt] = useState('');
  const [descriptionPrompt, setDescriptionPrompt] = useState('');
  const [tagUpdate, setTagUpdate] = useState('');
  const [scriptOutput, setScriptOutput] = useState(''); // State to store script output
  const [isLoading, setIsLoading] = useState(false);

  // This useEffect hook runs once, when the component mounts.
  useEffect(() => {
    fetch(`${API}get-prompts`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then(data => {
        setTitlePrompt(data.titlePrompt);
        setDescriptionPrompt(data.descriptionPrompt);
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }, []);

  // Establish WebSocket connection
  useEffect(() => {
    const ws = new WebSocket('wss://upload.snatch.live/socket');

    ws.onmessage = (event) => {
      try {
        const jsonData = JSON.parse(event.data);
        if (jsonData.type === 'initialLog' || jsonData.type === 'logUpdate') {
          // Update script output with the complete log
          setScriptOutput(jsonData.log.join('\n'));
        } else if (jsonData.type === 'logCleared') {
          // Clear the script output on log cleared
          setScriptOutput('');
        }
      } catch (error) {
        // If parsing fails, it's not JSON, so append it to the scriptOutput directly
        setScriptOutput((prevOutput) => prevOutput + '\n' + event.data); // Append new data to script output
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Clean up: close WebSocket connection when component unmounts
    return () => {
      ws.close();
    };
  }, []);  // The empty array argument means this useEffect hook runs only once.

  const handleImportSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);  // Start loading
    fetch(`${API}run-import-script`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tag: tagImport, tags, titlePrompt, descriptionPrompt }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('data', data);
        // ... (handle response)
      });
  };

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);  // Start loading
    fetch(`${API}run-update-script`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tag: tagUpdate }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // ... (handle response)
      });
  };

  // Function to stop the import script
  const stopImportScript = () => {
    fetch(`${API}stop-import-script`, {
      method: 'POST'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setIsLoading(false);  // Stop loading when script is stopped
        setScriptOutput((prevOutput) => prevOutput + '\n' + 'Import script stopped');
      })
      .catch(error => {
        console.error('Error stopping import script:', error);
      });
  };

  // Function to stop the update script
  const stopUpdateScript = () => {
    fetch(`${API}stop-update-script`, {
      method: 'POST'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setIsLoading(false);  // Stop loading when script is stopped
        setScriptOutput((prevOutput) => prevOutput + '\n' + 'Update script stopped');
      })
      .catch(error => {
        console.error('Error stopping update script:', error);
      });
  };

  return (
    <div>
      {/* Loading indicator */}
      {isLoading && (
        <div className="loading-indicator">
          Running script, please wait...
          {/* You can also use a spinner here */}
        </div>
      )}

      <div className="form-container">
        <div className="form-header">Import Script Form</div>
        <div className="form-description">
          Fill out the fields below to run the import script.
        </div>
        <form onSubmit={handleImportSubmit}>
          <div className="label-input-group">
            <label>
              Tag for Import:
              <input type="text" value={tagImport} onChange={e => setTagImport(e.target.value)} />
            </label>
          </div>
          <div className="label-input-group">
            <label>
              Tags (separated by newline):
              <textarea value={tags} onChange={e => setTags(e.target.value)} />
            </label>
          </div>
          <div className="label-input-group">
            <label>
              Title Prompt:
              <textarea value={titlePrompt} onChange={e => setTitlePrompt(e.target.value)} />
            </label>
          </div>
          <div className="label-input-group">
            <label>
              Description Prompt:
              <textarea value={descriptionPrompt} onChange={e => setDescriptionPrompt(e.target.value)} />
            </label>
          </div>
          <button type="submit" disabled={isLoading}>Run Import Script</button>
        </form>
        <button onClick={stopImportScript}>Stop Import Script</button> {/* Add stop button for import script */}
      </div>

      <div className="form-container">
        <div className="form-header">Update Script Form</div>
        <div className="form-description">
          Fill out the fields below to run the update script.
        </div>
        <form onSubmit={handleUpdateSubmit}>
          <div className="label-input-group">
            <label>
              Tag for Update:
              <input type="text" value={tagUpdate} onChange={e => setTagUpdate(e.target.value)} />
            </label>
          </div>
          <button type="submit" disabled={isLoading}>Run Update Script</button>
        </form>
        <button onClick={stopUpdateScript}>Stop Update Script</button> {/* Add stop button for update script */}
      </div>

      {/* Display the script output */}
      <div className="script-output-container">
        <div className="script-output-header">Script Output:</div>
        <div className="script-output-content">
          <pre>{scriptOutput}</pre>
        </div>
      </div>
    </div>
  );
}

export default App;